<script setup lang="ts">
  import { MpBox } from '@borg/ui';
  import { Badge } from '@borg/types';

  defineProps<{
    exclusive?: boolean;
  }>();

  const jobCard = useJobCard.inject();

  const badges = computed(() => {
    const jobBadges = jobCard.job && jobCard.job.badges?.length ? jobCard.job.badges : [];
    const organizationBadges = jobCard.organization && jobCard.organization !== 'incognito' && jobCard.organization.badges?.length ? jobCard.organization.badges : [];
    const badges = [...jobBadges, ...organizationBadges];
    return badges.reduce<Badge[]>((acc, value) => {
      const exists = acc.map(b => b.image).findIndex((id) => id === value.image) !== -1;
      if (!exists) {
        acc.push(value);
      }
      return acc;
    }, []);
  });
</script>

<template>
  <MpBox
    v-if="badges.length"
    :surface="exclusive ? '30' : '20'"
    :class="{
      'badges-container': true,
      'badges-container--exclusive': exclusive,
    }"
  >
    <img
      v-for="(badge, i) in badges"
      :key="i"
      class="badges-container__image"
      loading="lazy"
      :src="badge.image"
      alt="badge"
    />
  </MpBox>
</template>

<style scoped lang="scss">
  .badges-container {
    --parent-padding: var(--mp-space-60);

    padding: var(--mp-space-50) var(--parent-padding);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--mp-space-50) var(--mp-space-60);
    justify-content: center;
    width: calc(100% + 2 * var(--parent-padding));
    position: relative;
    left: calc(-1 * var(--parent-padding));
    margin-bottom: calc(-1 * var(--parent-padding));
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: 1px solid var(--mp-color-grey-200);

    @include dark-mode {
      border-color: var(--mp-color-grey-800);
    }

    &--exclusive {
      --parent-padding: var(--mp-space-70);

      margin-top: var(--mp-space-50);
      border-top: none;
    }

    &__image {
      object-fit: contain;
      width: fit-content;
      height: fit-content;
      max-width: 7.5rem;
      max-height: 3.2rem;
    }
  }
</style>
